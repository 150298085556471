import React, { Suspense, useEffect, useRef, useState, useMemo } from 'react'
import { Canvas, useFrame, useThree } from '@react-three/fiber'
import { useGLTF, useTexture, Loader, Environment, useFBX, useAnimations } from '@react-three/drei';
import { MeshStandardMaterial } from 'three/src/materials/MeshStandardMaterial';

import { sRGBEncoding } from 'three/src/constants';
import { Vector2 } from 'three';
import ReactAudioPlayer from 'react-audio-player';

import createAnimation from './converter';
import blinkData from './blendDataBlink.json';

import * as THREE from 'three';
import axios from 'axios';

import { useAudioRecorder } from 'react-audio-voice-recorder';

import './App.css';

/**
 * Lodash library, providing utility functions for common programming tasks.
 * @module _
 */
const _ = require('lodash');

/**
 * The backend URL for the application, retrieved from the environment variables.
 * @constant {string} host
 */
const host = process.env.REACT_APP_BACKEND_URL;

/**
 * Avatar component that renders a 3D avatar model with various textures and animations.
 * 
 * @param {Object} props - The properties object.
 * @param {string} props.avatar_url - The URL of the avatar model.
 * @param {boolean} props.speak - Flag to trigger speech animation.
 * @param {Function} props.setSpeak - Function to set the speak state.
 * @param {string} props.audio - Audio data for speech.
 * @param {Function} props.resetAudio - Function to reset the audio data.
 * @param {string} props.text - Text data for speech.
 * @param {Function} props.setText - Function to set the text data.
 * @param {Function} props.callbackTextSent - Callback function when text is sent.
 * @param {Function} props.setAudioSource - Function to set the audio source.
 * @param {boolean} props.playing - Flag to trigger animation playback.
 * @param {Function} props.setIsResponding - Function to set the responding state.
 * 
 * @returns {JSX.Element} The Avatar component.
 */
function Avatar({ avatar_url, speak, setSpeak, audio, resetAudio, text, setText, callbackTextSent, setAudioSource, playing, setIsResponding, updateSubtitles, isPlaying }) {

  let model = useGLTF(avatar_url);
  let morphTargetDictionaryBody = null;
  let morphTargetDictionaryLowerTeeth = null;
  const [history, setIshistory] = useState(true);

  const [
    hairNormalText,
    teethLowerColor,
    teethNormal,
    teethRoughness,
    bodyBase,
    bodyNormal,
    bodyRoughness,
    bodySpecular,
    eyesTexture,
    tshirtDiffuseTexture
  ] = useTexture([
    "/images/textures/Zachary.001_hair2Haircards_normal.png",
    "/images/textures/Zachary.001_lower_teeth_base_color.png",
    "/images/textures/Zachary.001_upper_teeth_normal.png",
    "/images/textures/Zachary.001_lower_teeth_roughness.png",
    "/images/textures/Zachary.001_body_base_color.png",
    "/images/textures/Zachary.001_body_normal.png",
    "/images/textures/Zachary.001_body_roughness.png",
    "/images/textures/Zachary.001_body_specular.png",
    "/images/textures/Zachary.001_eyes_base_color.png",
    "/images/textures/Zachary.001_HG_TSHIRT_Male.002_base_color.png"
  ]);

  _.each([
    hairNormalText,
    teethLowerColor,
    teethNormal,
    teethRoughness,
    bodyBase,
    bodyNormal,
    bodyRoughness,
    bodySpecular,
    eyesTexture,
    tshirtDiffuseTexture
  ], t => {
    // console.log(t)
    t.encoding = sRGBEncoding;
    t.flipY = false;
  });


  model.scene.traverse(node => {
    if (node.type === 'Mesh' || node.type === 'LineSegments' || node.type === 'SkinnedMesh') {

      if (node.name.includes("HG_Eyes001")) {
        node.material = new MeshStandardMaterial();
        node.material.map = eyesTexture;
        node.material.envMapIntensity = 1;
      }


      if (node.name.includes("Haircards")) {
        node.material.transparent = false;
        node.material.side = 2;
        node.material.color.setHex(0xfbe7a1);

        node.material.envMapIntensity = 1;
      }


      /* ******************** Mesh con materiali ************ */
      if (node.name.includes("HG_Body002")) {

        node.material.color.setHex(0xF5D7B6);
        node.material.envMapIntensity = 1.2;
        node.material.normalScale = new Vector2(0.6, 0.6);

        morphTargetDictionaryBody = node.morphTargetDictionary;
      }


      if (node.name.includes("HG_TeethLower001")) {

        node.material = new MeshStandardMaterial();
        node.material.map = teethLowerColor;
        node.normalMap = teethNormal;
        node.roughnessMap = teethRoughness;
        morphTargetDictionaryLowerTeeth = node.morphTargetDictionary;
      }
    }

  });



  const [clips, setClips] = useState([]);
  const mixer = useMemo(() => new THREE.AnimationMixer(model.scene), []);

  useEffect(() => {
    (async () => {
      if (speak === false)
        return;

      let response;
      if (audio) {
        if (history) {
          response = await makeSpeech(audio, true);
          setIshistory(false)
        } else {
          response = await makeSpeech(audio, false);
        }

        resetAudio()
      }
      else if (text) {
        if (history) {
          response = await makeSpeechFromText(text, true)
          setText('')
          callbackTextSent(false)
          setIshistory(false)
        } else {
          response = await makeSpeechFromText(text, false)
          setText('')
          callbackTextSent(false)
        }
      }

      startAnimation(response.data)
      updateSubtitles(response.data.text);
    })();
  }, [speak]);

  function startAnimation(data) {
    let { blendData, filename } = data;

    console.log(morphTargetDictionaryBody);

    let newClips = [
      createAnimation(blendData, morphTargetDictionaryBody, 'HG_Body002'),
      createAnimation(blendData, morphTargetDictionaryLowerTeeth, 'HG_TeethLower001')];

    filename = host + filename;

    setClips(newClips);
    setAudioSource(filename);
  }

  let idleFbx = useFBX('/idle.fbx');
  let { clips: idleClips } = useAnimations(idleFbx.animations);

  idleClips[0].tracks = _.filter(idleClips[0].tracks, track => {
    return track.name.includes("Head") || track.name.includes("Neck") || track.name.includes("Spine2");
  });

  idleClips[0].tracks = _.map(idleClips[0].tracks, track => {

    if (track.name.includes("Head")) {
      track.name = "head.quaternion";
    }

    if (track.name.includes("Neck")) {
      track.name = "neck.quaternion";
    }

    if (track.name.includes("Spine")) {
      track.name = "spine2.quaternion";
    }

    return track;

  });

  useEffect(() => {
    let blinkClip = createAnimation(blinkData, morphTargetDictionaryBody, 'HG_Body002');
    let blinkAction = mixer.clipAction(blinkClip);
    blinkAction.play();

  }, []);

  // Play animation clips when available
  useEffect(() => {
    if (playing === false || !isPlaying) {
      // Ferma tutte le animazioni attive
      mixer.stopAllAction();
      return;
    }

    _.each(clips, clip => {
      let clipAction = mixer.clipAction(clip);
      clipAction.setLoop(THREE.LoopOnce);
      clipAction.play();
    });

  }, [playing, isPlaying]);

  const modelRef = useRef();

  useFrame((state, delta) => {
    mixer.update(delta);
  });


  return (
    <group name="avatar">
      <primitive ref={modelRef}
        object={model.scene}
        dispose={null}
        position={[0, 0.40, 0.20]}
      />
    </group>
  );
}


/**
 * Sends an audio blob and history data to the server for speech processing.
 *
 * @param {Blob} blob - The audio blob to be sent.
 * @param {string} history - The history data to be sent.
 * @returns {Promise} - A promise that resolves with the server response.
 */
async function makeSpeech(blob, history) {
  const formData = new FormData();
  formData.append('audio', blob);
  formData.append('history', history);
  return axios.post(host + '/talk-sts', formData);
}

/**
 * Sends a POST request to generate speech from text.
 *
 * @param {string} text - The text to be converted to speech.
 * @param {Array} history - An array representing the history of previous texts.
 * @returns {Promise} - A promise that resolves with the response from the server.
 */
async function makeSpeechFromText(text, history) {
  return await axios.post(host + '/talk-tts', { text, history: history });
}

/**
 * The main application component.
 *
 * @component
 * @returns {JSX.Element} The rendered component.
 *
 * @example
 * return <App />;
 *
 * @description
 * This component handles the main functionality of the application, including:
 * - Managing audio recording and playback using `useAudioRecorder` and `ReactAudioPlayer`.
 * - Handling user input through a text area and buttons.
 * - Sending text input to the backend.
 * - Managing various states such as `speak`, `text`, `sending`, `audioSource`, `playing`, `blob`, and `isResponding`.
 * - Rendering a 3D scene using `Canvas` and `Scene` components.
 *
 * @property {Object} audioPlayer - A reference to the audio player element.
 * @property {boolean} speak - State to manage if the application should speak.
 * @property {string} text - State to manage the text input from the user.
 * @property {boolean} sending - State to manage if the text is being sent to the backend.
 * @property {string|null} audioSource - State to manage the source of the audio to be played.
 * @property {boolean} playing - State to manage if the audio is currently playing.
 * @property {Blob|null} blob - State to manage the recorded audio blob.
 * @property {boolean} isResponding - State to manage if the application is responding.
 * @property {Object} options - Options for the audio recorder.
 * @property {Function} startRecording - Function to start recording audio.
 * @property {Function} stopRecording - Function to stop recording audio.
 * @property {Function} togglePauseResume - Function to toggle pause/resume recording.
 * @property {Blob|null} recordingBlob - The recorded audio blob.
 * @property {boolean} isRecording - State to manage if the application is recording audio.
 * @property {boolean} isPaused - State to manage if the recording is paused.
 * @property {number} recordingTime - The duration of the recording.
 * @property {Object} mediaRecorde - The media recorder instance.
 * @property {Function} playerEnded - Function to handle the end of audio playback.
 * @property {Function} playerReady - Function to handle when the audio player is ready.
 * @property {Function} sendTextToBackend - Function to send the text input to the backend.
 */


function App() {

  const audioPlayer = useRef();

  const [speak, setSpeak] = useState(false);
  const [text, setText] = useState("");
  const [sending, setSending] = useState(false)
  const [audioSource, setAudioSource] = useState(null);
  const [playing, setPlaying] = useState(false);
  const [blob, setBlob] = useState(null);
  const [isResponding, setIsResponding] = useState(false);
  const [showSubtitles, setShowSubtitles] = useState(false);  // Stato per mostrare/nascondere il box
  const [subtitles, setSubtitles] = useState("");  // Stato per i sottotitoli
  const [fullSubtitles, setFullSubtitles] = useState(""); // Testo completo dei sottotitoli
  const [isPlaying, setIsPlaying] = useState(true);
  const [chatHistory, setChatHistory] = useState([]);
  const chatContainerRef = useRef(null);
  const [isChatMinimized, setIsChatMinimized] = useState(false);
  const [currentTypingMessageId, setCurrentTypingMessageId] = useState(null);
  const [partialMessage, setPartialMessage] = useState("");

  // Funzione per gestire la chiusura e apertura del box
  const toggleSubtitles = () => {
    setShowSubtitles(!showSubtitles);
  };

  const addMessageToChat = (message, isUser = false) => {
    const messageId = Date.now();
    if (isUser) {
      // I messaggi dell'utente vengono aggiunti immediatamente
      setChatHistory(prev => [...prev, {
        id: messageId,
        text: message,
        isUser,
        timestamp: new Date().toLocaleTimeString(),
        isComplete: true
      }]);
    } else {
      // Per i messaggi dell'avatar, iniziamo con un messaggio vuoto
      setChatHistory(prev => [...prev, {
        id: messageId,
        text: "",
        isUser,
        timestamp: new Date().toLocaleTimeString(),
        isComplete: false
      }]);
      setCurrentTypingMessageId(messageId);
      startProgressiveMessage(message, messageId);
    }
  };

  const startProgressiveMessage = (fullMessage, messageId) => {
    let currentCharIndex = 0;
    const messageLength = fullMessage.length;

    const updateMessageContent = () => {
      setChatHistory(prev => prev.map(msg => {
        if (msg.id === messageId) {
          const updatedText = fullMessage.slice(0, currentCharIndex + 1);
          return {
            ...msg,
            text: updatedText,
            isComplete: currentCharIndex === messageLength - 1
          };
        }
        return msg;
      }));
    };

    const calculateInterval = () => {
      if (audioPlayer.current && audioPlayer.current.audioEl.current) {
        const audioDuration = audioPlayer.current.audioEl.current.duration;
        // Dividiamo la durata dell'audio per il numero di caratteri
        // e aggiungiamo un piccolo ritardo per rendere la lettura più naturale
        return (audioDuration * 1000) / (messageLength * 1.2);
      }
      return 30; // fallback interval per una digitazione fluida
    };

    const typeNextChar = () => {
      if (currentCharIndex < messageLength && isPlaying) {
        updateMessageContent();
        currentCharIndex++;

        // Calcola il prossimo intervallo
        let nextInterval = calculateInterval();

        // Aggiungi una piccola variazione casuale per rendere la digitazione più naturale
        nextInterval *= 0.8 + Math.random() * 0.4; // variazione del 20% in più o in meno

        // Aggiungi una pausa più lunga per la punteggiatura
        const currentChar = fullMessage[currentCharIndex];
        if (['.', '!', '?', ',', ';', ':'].includes(currentChar)) {
          nextInterval *= 2;
        }

        setTimeout(typeNextChar, nextInterval);
      } else if (currentCharIndex === messageLength) {
        setCurrentTypingMessageId(null);
        setChatHistory(prev => prev.map(msg => {
          if (msg.id === messageId) {
            return { ...msg, isComplete: true };
          }
          return msg;
        }));
      }
    };

    const startTyping = () => {
      if (audioPlayer.current && audioPlayer.current.audioEl.current &&
        audioPlayer.current.audioEl.current.duration) {
        typeNextChar();
      } else {
        setTimeout(startTyping, 50);
      }
    };

    startTyping();
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      // Opzionale: potresti aggiungere un feedback visivo qui
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };


  const handleStop = () => {
    if (audioPlayer.current && audioPlayer.current.audioEl.current) {
      audioPlayer.current.audioEl.current.pause();
    }
    setIsPlaying(false);
    setPlaying(false);
    setAudioSource(null);
    setSpeak(false);
    setSending(false);

    // Completa immediatamente il messaggio corrente se presente
    if (currentTypingMessageId) {
      setChatHistory(prev => prev.map(msg => {
        if (msg.id === currentTypingMessageId) {
          const fullMessage = fullSubtitles; // o dove conservi il messaggio completo
          return {
            ...msg,
            text: fullMessage,
            isComplete: true
          };
        }
        return msg;
      }));
      setCurrentTypingMessageId(null);
    }
  };

  const updateSubtitles = (text) => {
    setShowSubtitles(true);
    setFullSubtitles(text);
    setSubtitles("");
    addMessageToChat(text, false); // Aggiungi la risposta dell'avatar alla chat
  };

  const clearChat = () => {
    setChatHistory([]);
  };


  // Funzione per sincronizzare i sottotitoli con l'audio
  const syncSubtitles = (subtitlesText, duration) => {
    const words = subtitlesText.split(' ');
    const interval = duration / words.length;

    let currentWordIndex = 0;
    setSubtitles("");

    const intervalId = setInterval(() => {
      if (currentWordIndex < words.length && isPlaying) {
        const currentWord = words[currentWordIndex];
        if (currentWord) {
          setSubtitles((prevSubtitles) => prevSubtitles + " " + currentWord);
        }
        currentWordIndex++;
      } else {
        clearInterval(intervalId);
      }
    }, interval * 1000);

    return intervalId;
  };

  useEffect(() => {
    if (chatContainerRef.current) {
      chatContainerRef.current.scrollTop = chatContainerRef.current.scrollHeight;
    }
  }, [chatHistory]);



  useEffect(() => {
    let intervalId;
    if (audioSource && playing && isPlaying) {
      const audioDuration = audioPlayer.current.audioEl.current.duration;
      intervalId = syncSubtitles(fullSubtitles, audioDuration);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [audioSource, playing, isPlaying]);

  const options = { downloadFileExtension: 'wav' }
  const { startRecording, stopRecording, togglePauseResume, recordingBlob, isRecording, isPaused, recordingTime, mediaRecorde }
    = useAudioRecorder(options);

  useEffect(() => {
    if (recordingBlob) {
      setBlob(recordingBlob);
      setSpeak(true);
      setShowSubtitles(false);
      setSubtitles("");
      setSending(true);
      setIsPlaying(true);

    }
  }, [recordingBlob]);

  //  // Effetto per aggiornare i sottotitoli quando cambia l'audio o inizia la riproduzione
  //  useEffect(() => {
  //   if (audioSource && playing) {
  //     setShowSubtitles(true);
  //     setSubtitles("Questi sono i sottotitoli sincronizzati con l'audio.");  // Sottotitoli dinamici qui
  //   }
  // }, [audioSource, playing]);  // Aggiorna quando cambia l'audio o lo stato di riproduzione

  // End of play
  /**
   * Handles the event when the player has ended.
   *
   * @param {Event} e - The event object.
   */
  function playerEnded(e) {
    setAudioSource(null);
    setSpeak(false);
    setPlaying(false);
    setSending(false);
    // setShowSubtitles(false);
    // setSubtitles("");
  }

  // Player is ready
  /**
   * Handles the event when the player is ready.
   *
   * @param {Event} e - The event object.
   */
  function playerReady(e) {
    audioPlayer.current.audioEl.current.play();
    setPlaying(true);
  }

  /**
   * Initiates the process of sending text to the backend and sets the state to indicate that the text is being sent and spoken.
   * 
   * @function
   * @name sendTextToBackend
   * @returns {void}
   */
  function sendTextToBackend() {
    if (text.trim()) {
      addMessageToChat(text, true); // Aggiungi il messaggio dell'utente alla chat
      setSending(true);
      setSpeak(true);
      setIsPlaying(true);
    }
  }

  return (
    <div className="full">
      <div className='inputs-container'>
        <div className='text-area-wrapper'>
          <textarea
            className='text-area-element'
            placeholder='Type a question...'
            rows={1}
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && text !== '' && !sending && !playing) {
                e.preventDefault();
                sendTextToBackend();
              }
            }}
          />

          {/* Gestione pulsanti basata sullo stato */}
          {playing ? (
            // Mostra sia il pulsante di stop che lo spinner quando l'avatar sta parlando
            <>
              <button onClick={handleStop} className="stop-button">
                <i className="fas fa-stop-circle" />
              </button>
              <button className='record-button' disabled>
                <i className="fas fa-spinner fa-spin" />
              </button>
            </>
          ) : sending ? (
            // Mostra solo lo spinner quando sta caricando
            <button className='record-button' disabled>
              <i className="fas fa-spinner fa-spin" />
            </button>
          ) : (
            // Mostra i pulsanti di invio e registrazione quando non sta facendo nulla
            <>
              <button
                className={`text-area-button ${text === '' ? 'text-area-button-disabled' : ''}`}
                onClick={sendTextToBackend}
                disabled={text === ''}
              >
                <i className="fas fa-paper-plane" />
              </button>

              {isRecording ? (
                <button onClick={stopRecording} className='record-button'>
                  <i className="fas fa-stop" />
                </button>
              ) : (
                <button onClick={startRecording} className='record-button'>
                  <i className="fas fa-microphone" />
                </button>
              )}
            </>
          )}
        </div>
      </div>

      {showSubtitles && (
        <div className={`chat-container ${isChatMinimized ? 'minimized' : ''}`}>
          <div className="chat-header">
            <h2>Chat History</h2>
            <div className="chat-controls">
              {/* Pulsante per pulire la chat */}
              <button
                className="header-button clear-button"
                onClick={clearChat}
                title="Clear chat history"
              >
                <i className="fas fa-broom" />
              </button>

              {/* Pulsante per minimizzare/massimizzare */}
              <button
                className="header-button minimize-button"
                onClick={() => setIsChatMinimized(!isChatMinimized)}
                title={isChatMinimized ? "Expand chat" : "Minimize chat"}
              >
                <i className={`fas ${isChatMinimized ? 'fa-expand' : 'fa-compress'}`} />
              </button>

              {/* Pulsante di chiusura */}
              <button
                className="header-button close-button"
                onClick={() => setShowSubtitles(false)}
                title="Close chat"
              >
                <i className="fas fa-times" />
              </button>
            </div>
          </div>

          <div className="chat-messages" ref={chatContainerRef}>
            {chatHistory.map(message => (
              <div
                key={message.id}
                className={`chat-message ${message.isUser ? 'user-message' : 'avatar-message'}`}
              >
                <div className="message-content">
                  <p className={message.isComplete ? 'complete' : ''}>
                    {message.text}
                  </p>
                  <div className="message-footer">
                    <span className="message-time">{message.timestamp}</span>
                    {!message.isUser && message.isComplete && (
                      <button
                        className="copy-button"
                        onClick={() => copyToClipboard(message.text)}
                        title="Copy to clipboard"
                      >
                        <i className="fas fa-copy" />
                      </button>
                    )}
                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <ReactAudioPlayer
        src={audioSource}
        ref={audioPlayer}
        onEnded={playerEnded}
        onCanPlayThrough={playerReady}

      />

      {/* <Stats /> */}
      <Canvas dpr={2} onCreated={(ctx) => {
        ctx.gl.physicallyCorrectLights = true;
      }}
      >
        <Scene
          speak={speak}
          setSpeak={setSpeak}
          blob={blob}
          setBlob={setBlob}
          text={text}
          setText={setText}
          setSending={setSending}
          setAudioSource={setAudioSource}
          playing={playing}
          setIsResponding={setIsResponding}
          updateSubtitles={updateSubtitles}
          isPlaying={isPlaying}  // Aggiungi questa prop
        />
      </Canvas>
      <Loader dataInterpolation={(p) => `Loading... please wait`} />
    </div>
  )
}


/**
 * Bg component renders a background mesh with a texture.
 * 
 * This component uses the `useThree` hook to access the camera and viewport
 * from the Three.js context. It also uses the `useTexture` hook to load a texture
 * from a specified URL.
 * 
 * The component calculates the aspect ratio of the texture and adjusts the scale
 * of the mesh accordingly to maintain the aspect ratio. The position and rotation
 * of the mesh are also set based on the camera's properties.
 * 
 * @returns {JSX.Element|null} A mesh element with the background texture or null if the texture is not loaded.
 */
function Bg() {
  const { camera, viewport } = useThree();
  const planeRef = useRef(null);

  const texture = useTexture('/images/ministero-economia.jpg');

  if (!texture.image) {
    return null;
  }

  const textureAspect = texture.image.width / texture.image.height;

  const vFov = camera.fov * (Math.PI / 180);
  const planeHeight = 2 * Math.tan(vFov / 2) * Math.abs(camera.position.z);
  const planeWidth = planeHeight * viewport.aspect;
  const scale_d = 320
  const scale = planeWidth / planeHeight < textureAspect ?
    [planeHeight * textureAspect * scale_d, planeHeight * scale_d, 1] :
    [planeWidth * scale_d, (planeWidth / textureAspect) * scale_d, 1];
  const position = [0, -46, camera.far / 2 * -1];
  const rotation = [-Math.PI / 20, 0, 0];

  return (
    <mesh position={position} scale={scale} ref={planeRef} rotation={rotation}>
      <planeBufferGeometry args={[1, 1]} />
      <meshBasicMaterial map={texture} attach="material" />
    </mesh>
  );

}

/**
 * Scene component that sets up the 3D environment and renders the avatar.
 *
 * @param {Object} props - The properties object.
 * @param {boolean} props.speak - Indicates if the avatar is speaking.
 * @param {Function} props.setSpeak - Function to set the speak state.
 * @param {Blob} props.blob - Audio blob for the avatar.
 * @param {Function} props.setBlob - Function to set the audio blob.
 * @param {string} props.text - Text to be spoken by the avatar.
 * @param {Function} props.setText - Function to set the text.
 * @param {Function} props.setSending - Callback function when text is sent.
 * @param {Function} props.setAudioSource - Function to set the audio source.
 * @param {boolean} props.playing - Indicates if the audio is playing.
 * @param {Function} props.setIsResponding - Function to set the responding state.
 *
 * @returns {JSX.Element} The Scene component.
 */
function Scene({ speak, setSpeak, blob, setBlob, text, setText, setSending, setAudioSource, playing, setIsResponding, updateSubtitles, isPlaying }) {
  const { viewport, camera } = useThree();

  useEffect(() => {
    if (camera.isPerspectiveCamera) {
      camera.fov = 100;
      camera.aspect = window.innerWidth / window.innerHeight;
      camera.near = 0.1;
      camera.far = 1000;
      camera.zoom = 1.8;
      camera.position.set(0, 2, 1);
      camera.updateProjectionMatrix();
      camera.lookAt(new THREE.Vector3(0, 1.65, -1))
    }
  }, [camera]);

  return (
    <>

      {/*<FloorPlane/>*/}
      <Suspense fallback={null}>
        <Environment background={false} files="/images/photo_studio_loft_hall_1k.hdr" />
      </Suspense>

      <Suspense fallback={null}>
        <Bg />
      </Suspense>

      <Suspense fallback={null}>
        <Avatar
          avatar_url="/Avatar_09_1.glb"
          speak={speak}
          setSpeak={setSpeak}
          audio={blob}
          resetAudio={() => setBlob(null)}
          text={text}
          setText={setText}
          callbackTextSent={setSending}
          setAudioSource={setAudioSource}
          playing={playing}
          setIsResponding={setIsResponding}
          updateSubtitles={updateSubtitles}
          isPlaying={isPlaying}  // Aggiungi questa prop
        />
      </Suspense>
    </>
  )
}

export default App;
